import React from "react";
import { Accordion, TextButton } from "zera";

import { showTexts } from "../../helpers";
import {
  FaqSectionContainer,
  FaqSectionContent,
  FaqSectionHeader,
  FaqSectionOptionsContent,
  FaqSectionOptionsTitle,
  FaqSectionSeparator,
} from "./style";

export interface IFaq {
  title: string;
  text: string;
}
export interface IFaqSectionProps {
  faq: IFaq[];
  internationalization: "enUS" | "es" | "ptBR";
}

export const FaqSection: React.FunctionComponent<IFaqSectionProps> = (
  props
) => {
  const showTextsi18n = showTexts(props.internationalization);

  const { innerWidth: width } = window;
  const [amountAccordions, setAmountAccordions] = React.useState(3);
  const [textButton, setTextButton] = React.useState(
    showTextsi18n.titleButtonFaqSection.open
  );

  const [widthDimensions, setWidthDimensions] = React.useState(width);

  const listAccordions = React.useCallback(() => {
    if (props.faq?.length > 0 && props.faq !== undefined) {
      const accordiosn = props.faq.filter(
        (_, index) => index <= amountAccordions
      );
      return accordiosn;
    } else {
      const accordiosn = showTextsi18n.faq.filter(
        (_, index) => index <= amountAccordions
      );
      return accordiosn;
    }
  }, [amountAccordions, props.faq, showTextsi18n]);

  const handleUpdateListAccordions = React.useCallback(() => {
    if (amountAccordions - 1 >= showTextsi18n.faq.length) {
      const homeHeight = 0;
      const whenToUseHeight =
        homeHeight +
        document.getElementById("home").clientHeight +
        parseInt(
          window.getComputedStyle(document.getElementById("home")).marginBottom,
          10
        ) +
        parseInt(
          window.getComputedStyle(document.getElementById("home")).marginTop,
          10
        );

      const howToUseHeight =
        whenToUseHeight +
        document.getElementById("whenToUse").clientHeight +
        parseInt(
          window.getComputedStyle(document.getElementById("whenToUse"))
            .marginBottom,
          10
        ) +
        parseInt(
          window.getComputedStyle(document.getElementById("whenToUse"))
            .marginTop,
          10
        ) +
        parseInt(
          window.getComputedStyle(document.getElementById("howToUse"))
            .marginTop,
          10
        );

      const frequentlyQuestionsHeight =
        howToUseHeight +
        document.getElementById("howToUse").clientHeight +
        parseInt(
          window.getComputedStyle(document.getElementById("howToUse"))
            .marginBottom,
          10
        ) +
        parseInt(
          window.getComputedStyle(
            document.getElementById("frequentlyQuestions")
          ).marginTop,
          10
        );

      window.scrollTo(0, frequentlyQuestionsHeight * 0.99);

      setAmountAccordions(3);
    } else if (amountAccordions < showTextsi18n.faq.length) {
      setAmountAccordions(amountAccordions + 4);
    }
  }, [amountAccordions, showTextsi18n]);

  const updateDimensions = () => {
    const width = window.innerWidth;
    setWidthDimensions(width);
  };

  React.useEffect(() => {
    if (amountAccordions - 1 >= showTextsi18n.faq.length) {
      setTextButton(showTextsi18n.titleButtonFaqSection.close);
    } else if (amountAccordions <= showTextsi18n.faq.length) {
      setTextButton(showTextsi18n.titleButtonFaqSection.open);
    }
  }, [amountAccordions, showTextsi18n]);

  React.useEffect(() => {
    updateDimensions();
    window.addEventListener("resize", updateDimensions);
    return () => window.removeEventListener("resize", updateDimensions);
  }, [width]);

  return (
    <FaqSectionContainer id="frequentlyQuestions">
      <FaqSectionContent
        paddingLeft={widthDimensions <= 520 ? "1.5rem" : ""}
        paddingRight={widthDimensions <= 520 ? "1.5rem" : ""}
      >
        <FaqSectionHeader>
          <FaqSectionOptionsTitle
            text={showTextsi18n.titleFaqSection}
            fontSize={widthDimensions <= 520 ? "7" : "8"}
          />
        </FaqSectionHeader>
        <FaqSectionSeparator margin={widthDimensions <= 520 ? 2.5 : 4} />
        <FaqSectionOptionsContent>
          {listAccordions().map((accordion, index) => (
            <React.Fragment key={index}>
              <Accordion
                title={accordion.title}
                text={accordion.text}
                typeBorder={widthDimensions <= 520 ? "complete" : "onlyBottom"}
              />
              <FaqSectionSeparator
                margin={listAccordions().length - 1 === index ? 0 : 1.5}
              />
            </React.Fragment>
          ))}
        </FaqSectionOptionsContent>
        <FaqSectionSeparator margin={2.5} />
        <TextButton
          disabled={false}
          label={textButton}
          labelLast=""
          onPress={handleUpdateListAccordions}
          type="ghost"
          width="18rem"
        />
      </FaqSectionContent>
    </FaqSectionContainer>
  );
};

export default FaqSection;
