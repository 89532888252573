import styled from "styled-components";

import { Typography } from "zera";

interface IFaqSectionSeparatorProps {
  margin: number;
}

interface IFaqSectionContentProps {
  paddingRight: string;
  paddingLeft: string;
}

export const FaqSectionContainer = styled.section`
  flex: 1;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const FaqSectionContent = styled.div<IFaqSectionContentProps>`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding-left: ${({ paddingLeft }) => paddingLeft};
  padding-right: ${({ paddingRight }) => paddingRight};
`;

export const FaqSectionHeader = styled.section`
  max-width: 65rem;
  width: 100%;
  height: 3rem;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
`;

export const FaqSectionOptionsContent = styled.section`
  max-width: 65rem;
  width: 100%;
  justify-content: flex-start;
`;

export const FaqSectionOptionsTitle = styled(Typography).attrs(() => ({
  fontFamily: "Work Sans",
  fontWeight: 600,
  lineHeight: "1.5",
}))``;

export const FaqSectionSeparator = styled.div<IFaqSectionSeparatorProps>`
  margin-top: ${({ margin }) => margin / 2}rem;
  margin-bottom: ${({ margin }) => margin / 2}rem;
`;
