import { enUS, es, ptBR } from "../../i18n";

type TypeInternationalization = "enUS" | "es" | "ptBR";

const showTexts = (internationalization: TypeInternationalization) => {
  const i18n = { enUS, es, ptBR };

  return {
    faq: i18n[internationalization].faq,
    titleFaqSection: i18n[internationalization].titleFaqSection,
    titleButtonFaqSection: i18n[internationalization].titleButtonFaqSection,
  };
};

export default showTexts;
