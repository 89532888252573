import { FaqSection, IFaq } from "./modules/FaqSection/FaqSection";

type TypeInternationalization = "enUS" | "es" | "ptBR";

export default function Root() {
  const internationalization = localStorage.getItem(
    "@conecta:internationalization"
  ) as TypeInternationalization;

  const faq = JSON.parse(localStorage.getItem("@conecta:faq")) as IFaq[];

  return (
    <FaqSection
      faq={faq}
      internationalization={
        internationalization?.length > 0 ? internationalization : "ptBR"
      }
    />
  );
}
